
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.packet-badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  color: var(--White, #fff);
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  letter-spacing: -0.12px;
  margin-left: 14px;
  
  @include mobile {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.badge-basic {
    background-color: gray;
  }

  &.badge-standard {
    background-color: blue;
  }

  &.badge-premium {
    background-color: #515151;
  }

  &.badge-pro {
    background-color: #151515;
  }
}
