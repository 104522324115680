
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.phone-with-prefix {
  height: 72px;
  margin-bottom: 25px;
  &__content {
    display: flex;
    width: 100%;
    position: relative;
    gap: 10px;
  }
  &__separator {
    width: 1px;
    height: 30px;
    position: absolute;
    left: 105px;
    background-color: #d0d5dd;
    bottom: 10px;
    z-index: 3;
    display: none;
  }
  .select {
    padding-bottom: 7px;
    padding-left: 3px;
    &__wrapper {
      z-index: 2;
      width: 90px;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    &__value-container {
      padding: 0 0 0 20px !important;
      color: $black300;
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 19.6px */
    }
    &__label {
      margin-bottom: 5px;
    }
    &__indicator {
      padding: 0;
      width: 20px;
      height: 20px;
      margin-left: -26px;
    }
    &__control {
      border-radius: 3px !important ;
      max-height: 40px !important;
      min-height: 40px !important;
      &--is-focused {
        box-shadow: none !important;
      }
    }
  }
  .input {
    height: 40px;
    max-height: 40px;

    &__wrapper {
      width: 100%;
      padding-top: 26px;
      margin-bottom: 0;
    }
  }
}
