
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .profile {
  .dashboard-content {
    margin-top: -17px;
  }

  &__wrapper {
    display: flex;
    @include desktop {
      justify-content: space-between;
      gap: 40px;
    }
  }

  &__right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include desktop {
      position: static;
      max-width: 726px;
    }
    @include mobile {
      .tab-content {
        left: -20px;
        top: -101px;
        width: 100vw;
        z-index: 55555550;
        display: flex;
        flex-direction: column;
      }
    }
    width: 100%;
  }
  div &__button {
    margin-top: 40px;
    height: 40px;
    min-height: 40px;
    @include desktop {
      max-width: 166px;
    }
  }
  &__form {
    max-width: 480px;
    margin-top: 24px;

    &-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        width: 217px;
      }
    }
  }
  &__menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    @include desktop {
      border-radius: 4px;
      background: var(--White, #fff);
      gap: 0;
      overflow: hidden;
      max-width: 302px;
    }
  }
  &__menu-subheader {
    color: var(--Black-300, #515151);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    padding-bottom: 12px;
    max-width: 480px;
  }
  &__communication-option {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--Black-500, #151515);
    font-feature-settings: "salt" on;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;
    padding: 20px 0;
    max-width: 480px;
  }

  &__right-wrapper {
    border-radius: 4px;
    background: #fff;
    height: 100%;
    padding: 25px 20px;
    @include desktop {
      padding: 35px 60px 64px 60px;
    }

    @include mobile {
      flex: 1;
      height: unset;
    }
  }

  &__success-message {
    max-width: 450px;
    button {
      margin-top: 20px;
    }
  }

  &__menu-header {
    display: flex;
    align-items: center;
    gap: 16px;
    color: var(--Black-500, #151515);
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 26.1px */
    letter-spacing: 0.18px;
    margin-bottom: 32px;
  }

  &__menu-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    color: var(--Black-500, #151515);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    cursor: pointer;
    padding: 0px 15px;
    border-radius: 4px;
    background: #fff;
    transition: 0.3s;
    position: relative;
    height: 56px;

    @include desktop {
      border-radius: unset;
      padding: 12px 20px;
      &--active {
        .profile__menu-icon {
          background: var(--Black-400, #333);
          .svg path {
            stroke: $gray100;
          }
        }
      }
    }
    &:hover {
      background: var(--Grey, #f5f5f5);
    }
  }

  &__menu-chevron {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: rotate(-90deg) translateX(50%);
    .svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--Black-500, #151515);
      }
    }
    @include desktop {
      display: none;
    }
  }

  &__menu-icon {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg {
      width: 20px;
      height: 20px;
      path {
        transition: 0.3s;
        stroke: $black500;
      }
    }
    &--right {
      background: var(--Black-400, #333);
      .svg path {
        stroke: $gray100;
      }
    }
  }
  &__right-back {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--Black-300, #515151);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    margin-bottom: 20px;
    rect {
      display: none;
    }
    @include desktop {
      display: none;
    }
  }

  &__table {
    width: 100%;

    tr {
      &:last-child td {
        border-bottom: none;
      }
    }
  }
  &__table-label {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    border-bottom: 1px solid #c8c8c8;
    width: 50%;
    text-align: left;
  }

  &__table-cell {
    color: var(--Black-500, #151515);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #c8c8c8;
    text-align: left;
    width: 50%;

    &--space-between {
      display: flex;
      justify-content: space-between;
    }
  }
}
