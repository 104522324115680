
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.enter-code {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .code-input {
    width: 44px;
    height: 56px;
    font-size: 24px;
    text-align: center;
    border-radius: 4px;
    outline: none;
    transition: 0.3;
    background: $gray200;
    border: none;
  }
}
