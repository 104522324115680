
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.post-type-switch {
  border-radius: 50px;
  background: var(--White, #fff);
  padding: 3px;
  display: flex;

  @include mobile {
    width: 100%;
    justify-content: space-between;
  }

  &__item {
    padding-inline: 26px;
    transition: 0.3s;
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    cursor: pointer;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 20px;
    @include mobile {
      padding-inline: 13px;
    }
    svg {
      height: 16px;
      width: 16px;
    }
    &--active {
      background: var(--Black-400, #333);
      color: var(--White, #fff);
    }

    &--locked {
      color: var(--Gray-800, #9c9c9c);
      svg {
        path {
          stroke: #9c9c9c;
        }
      }
    }
  }

  &__upper-package {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    b {
      font-weight: 600;
      cursor: pointer;
    }
    @include desktop {
      text-align: right;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;
    @include mobile {
      width: 100%;
    }
  }
}
