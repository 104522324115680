
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.dtb {
  align-items: center;
  display: flex;
  height: 160px;
  max-width: 1221px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  justify-content: space-between;

  @include mobile {
    max-height: 118px;
  }

  &__bg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__logout {
    position: absolute;
    top: 16px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--Gray-500, #d5d5d5);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    z-index: 2;

    @include desktop {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    background: var(
      --Dark-gradient,
      linear-gradient(180deg, #414141 0%, #0f0f0f 100%)
    );
    padding: 0 20px;
    width: 100%;
    @include desktop {
      padding-left: 139px;
      padding-right: 80px;
    }
  }
  &__logo {
    max-width: 102px;
  }
  &__logout-wrapper {
    color: $blue500;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    margin-left: auto;
    display: flex;
    align-items: center;
    @include desktop {
      display: none;
    }
    .svg path {
      stroke: $blue500;
    }
    span {
      margin-left: 10px;
    }
  }
  &__user {
    margin-left: auto;
  }
  &__user-label {
    color: $graphite500;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -0.14px;
    .context-menu__trigger {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  &__user-submenu {
    .context-menu__item {
      min-width: 165px;
    }
  }
}
