
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.users-table {
  // margin-top: 20px;
  .rc-table-placeholder {
    display: none;
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  .user-status {
    padding-left: 5px;
    @include tablet {
      padding-left: unset;
    }
  }
  .users-table__color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .rc-table-thead th {
    color: $black300;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-align: left;
    padding: 5px 20px;
    padding-bottom: 10px;
    @include desktop {
      position: sticky;
      top: 0px;
      padding-top: 34px;
      background-color: #f2f2f2;
      z-index: 1;
    }
    &:first-child {
      font-weight: 600;
      z-index: 2;
      // border-top-left-radius: 6px;
    }

    &:last-child {
      // border-top-right-radius: 6px;
      border-color: transparent;
    }
  }
  .rc-table-tbody {
    tr {
      // border-bottom: 5px solid $gray300;
      box-shadow: 0px 0px 2px 0px $gray300;
      border-radius: 3px !important;
      @include tablet {
        box-shadow: 0;
      }

      &:last-child,
      &:nth-last-child(2) {
        .context-menu--left {
          top: unset;
          bottom: 20px;
        }
      }
    }
    td {
      transition: 0.3s;
      background-color: $white;
      color: var(--Black-400, #333);
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      letter-spacing: -0.12px;
      margin-bottom: 1px;
      padding: 15px 0;
      text-align: left;
      border: none;
      vertical-align: middle;
      @include tablet {
        padding: 15px 20px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        // box-shadow: 0px 1px 0px 0px $gray600;
      }

      @include mobile{
        min-height: 45px;
      }

      &:first-child {
        text-align: left;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        padding-left: 15px;
      }
      &:last-child {
        overflow: unset;
        text-align: center;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        padding-right: 15px;
      }
    }
    .rc-table-cell-row-hover {
      @include tablet {
        background-color: $gray300;
      }
    }
    .rc-table-expanded-row-level-1 {
      transition: 0.3s;
      // border-bottom: 5px solid $gray300;
      // td {
      //   padding: 0;
      // }
      td {
        padding-top: 0;
      }
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        height: 5px;
        background-color: #fff;
      }
    }
  }
  .pagination {
    margin-top: 15px;
  }
  &__no-data {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    &--paragraph {
      text-align: center;
      color: $graphite500;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.16px;
    }
  }

  &__send-invoice {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.user-table-row {
  &__expand {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    background-color: $white;
    padding-bottom: 15px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // box-shadow: 0px 1px 1px 0px $gray600;
  }
  &__expand-top {
    padding-left: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  &__grid {
    display: grid;
    grid-template-columns: 132px 1fr;
    gap: 25px;
    row-gap: 10px;
    width: 100%;
  }
  &__grid-item {
    display: flex;
    align-items: center;
    gap: 10px;
    &--label {
      color: var(--Gray-800, #9c9c9c);
    }
    &:nth-child(even) {
      word-break: break-all;
    }
  }
  &__expand-top-location {
    text-align: left;
    width: 50%;
  }
  &__expand-top-status {
    width: 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  &__expand-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
  }
  &__expand-item {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-right: 45px;
    cursor: pointer;
    &:last-child {
      margin-right: unset;
    }
  }
}

.users-table-mobile {
  &__name {
    text-align: left;
    // padding-left: 15px;
  }
  &__email {
    text-align: right;
    padding-right: 20px;
    word-break: break-all;
  }
}
