
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.checkbox-wrapper {
  display: inline-block;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  input {
    display: none;
    &:checked ~ small {
      background: #151515;
      transition: 0.3s;
    }
    &:checked ~ small:before {
      transform: translate(22px, 0px);
      transition: 0.3s;
      box-shadow: 3px 0 3px rgba(0, 0, 0, 0.25);
      background: #fff;
    }
    &:checked ~ .product__tile-light {
      display: none;
    }
    &:not(:checked) ~ .product__tile-green {
      display: none;
    }
  }
  small {
    display: inline-block;
    width: 48px;
    height: 25px;
    background: #b2b2b2;
    border-radius: 30px;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      width: 22px;
      height: 22px;
      background: #fff;
      border-radius: 50%;
      top: 1px;
      left: 2px;
      transition: 0.3s;
      box-shadow: -3px 0 3px rgba(0, 0, 0, 0.25);
    }
  }

  &.disabled {
    small {
      background: var(--Gray-400, #e4e4e4);
    }
  }
}

.switcher {
  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 25px;
    .data-tip {
      position: relative;
      top: 3px;
    }
  }
  .svg {
    margin: 0 !important;
  }
  p {
    font-size: 13px;
    font-weight: 700;
  }
  .checkbox-wrapper {
    justify-content: flex-start;
  }
}
