
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
div.how-it-works {
  display: flex;
  gap: 125px;
  padding: 160px 20px;
  h2 {
    @include Heading1;
    max-width: 505px;
  }
  p {
    @include Body1;
    max-width: 505px;
    margin-top: 30px;
  }

  &__left {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    span {
      background: #d9d9d9;
      height: 50%;
      &:nth-child(1) {
        width: calc(66.6% - 10px);
      }
      &:nth-child(2) {
        width: calc(33.3% - 10px);
      }
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
  &__right {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}
