
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .payments {
  &__label {
    color: var(--Black-500, #151515);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    letter-spacing: -0.16px;
    text-align: left;

    &--downgrade {
      margin-top: 20px;
      display: flex;
      gap: 4px;
      &-gray {
        color: #9c9c9c;
      }
    }
  }

  &__package {
    position: relative;
    border-radius: 5px;
    background: var(--Black-300, #515151);
    padding: 14px 20px 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    @include desktop {
      padding: 18px 25px;
    }
  }

  &__choose-packet {
    position: absolute;
    right: 0;
    width: 153px;
    top: 50%;
    font-size: 12px;
    transform: translateY(-50%);
  }

  &__package-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
  }

  &__package-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: var(--Black-400, #333);
    border-radius: 5px;
    opacity: 0.6;
  }

  &__package-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--Gray-100, #f8f8f8);
    position: relative;
    z-index: 1;
    font-family: "Playfair Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.36px;
    text-align: left;
  }
  &__package-name {
    font-size: 18px;
    text-transform: uppercase;
  }

  &__price {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -0.24px;
  }

  &__package-button {
    background: var(--Gray-300, #efefef);
    max-width: 128px;
    min-height: 32px;
    height: 32px;
    z-index: 2;
    border-radius: 50px;
  }

  &__package-price {
    font-size: 24px;
    font-weight: 500;
    margin-right: 4px;
  }

  &__payment-methods {
    margin-top: 40px;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .button {
        width: 153px;
        padding: 0;
        font-size: 12px;
      }
    }

    &--items-wrapper {
      margin-top: 20px;
    }

    &--label {
      font-size: 14px;
      display: flex;
    }

    &--item {
      position: relative;
      display: flex;
      height: 50px;
      padding: 12px 25px;
      align-items: center;
      align-self: stretch;
      margin-top: 10px;
      border-radius: 5px;
      background: var(--Gray-100, #f8f8f8);
      font-size: 14px;
      cursor: pointer;
    }

    &--toggler {
      position: absolute;
      z-index: 99;
      width: 80%;
      height: 100%;
    }

    &--number {
      display: flex;
      flex-grow: 4;
      margin-left: 15px;
    }

    &--options {
      font-weight: 600;
      flex-grow: 0.1;
      color: #10b969;
      display: flex;
      justify-content: flex-end;
    }

    &--checked {
      margin-left: 10px;
    }

    &--trash {
      flex-grow: 0.1;
      width: 24px;
      height: 24px;
      path {
        stroke: #9c9c9c;
      }
    }
  }

  &__invoices {
    margin-top: 40px;

    &--items-wrapper {
      margin-top: 20px;
    }

    &--label {
      font-size: 14px;
      display: flex;
      margin-bottom: 20px;
      gap: 4px;

      &--bold {
        font-weight: 600;
      }
    }

    &--item {
      height: 40px;
      border-radius: 5px;
      background: #f8f8f8;
      display: flex;
      align-self: stretch;
      align-items: center;
      padding: 12px 25px;
      font-size: 12px;
    }

    &--date {
      display: flex;
      flex-grow: 2;
    }

    &--amount {
      display: flex;
      flex-grow: 4;
    }

    &--cloud-icon {
      flex-grow: 0.1;
    }

    &--download {
      font-weight: 600;
    }
  }

  &__download {
    display: flex;
    gap: 8px;
    cursor: pointer;
    align-items: center;
  }

  &__deactivate {
    color: var(--Black-300, #515151);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    margin-top: 20px;
    cursor: pointer;
  }

  &__subtitle {
    color: var(--Black-300, #515151);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &__option {
    color: var(--Black-500, #151515);
    font-feature-settings: "salt" on;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__option-wrapper {
    border-radius: 5px;
    background: var(--Gray-300, #efefef);
    padding: 12px 25px;
  }
}
