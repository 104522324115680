
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.reset-password {
  &__dont-remember {
    color: $black300;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    width: 100%;
    text-align: left;
    margin-top: -8px;
  }
  &__link {
    color: $black300;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%; /* 23.2px */
    margin-top: 30px;
  }
  .login-wrapper__submit {
    margin-bottom: 0;
  }
}
