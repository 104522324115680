
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.edit-personal-data {
  &__form {
    @include desktop {
      min-width: 377px;
    }
    margin-top: 30px;
    .static-alert--show {
      margin-bottom: 30px;
    }
  }
  &__actions {
    margin-top: 60px;
  }

  &__warning {
    transition: 0.3s;
    overflow: hidden;
    margin-top: -20px;

    opacity: 1;
    max-height: 100px;
    &--hidden {
      opacity: 0;
      max-height: 0;
      padding: 0;
      margin: 0;
    }
  }

  .input__wrapper {
    @include mobile {
      margin-bottom: 0;
    }
  }
}
