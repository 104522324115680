
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.analyze-status {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 0 10px 0 15px;
  height: 26px;
  border-radius: 50px;
  color: var(--Black-400, #333);
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  letter-spacing: -0.12px;
  transition: 0.3s;
  width: fit-content;

  @include mobile {
    padding: 0;
    width: 26px;
    height: 26px;
  }

  &--onlyDesktopVersion {
    @include mobile {
      padding: 0 10px 0 15px;
      width: fit-content;

      .analyze-status__text {
        display: block;
      }
    }
  }

  &--inProgress {
    background: var(--Gray-300, #efefef);
  }
  &--completed {
    background: rgba(56, 213, 139, 0.3);
  }

  &--pending {
    background: var(--Gray-200, #f6f6f6);
  }

  &--canceled {
    background: rgba(242, 146, 37, 0.3);
  }
  svg {
    path {
      stroke: #333333;
    }
  }

  &__text {
    @include mobile {
      display: none;
    }
  }
}
