@mixin label {
  color: #2e2e2e;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.14px;
}

@mixin paragraph {
  color: #2e2e2e;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
  text-align: left;
}

/* Heading 1 */
@mixin Heading1 {
  color: $black500;
  font-family: "Playfair Display";
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.64px;
}

/* Body 1 */
@mixin Body1 {
  color: $black300;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.16px;
}

/* Body 2 */
@mixin Body2 {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%; /* 21.7px */
}

/* Heading 4 */
@mixin Heading4 {
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.36px;
}
