
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .transactions-table {
  &__button {
    position: absolute;
    right: 52px;
    top: 58px;
    max-width: 152px;
    height: 40px;
    min-height: 40px;
    display: none;
    @include desktop {
      display: flex;
    }
  }
  .side-modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__summary {
    table {
      margin-top: 0;
      .rc-table-row {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
          color: var(--Black-400, #333);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 18px */
        }
      }
    }
  }
}
