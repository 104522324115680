
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .dashboard {
  width: 100%;
  position: relative;
  background-color: $gray400;
  overflow: auto;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: 100dvh;

  @include mobile {
    padding-bottom: 60px;
  }
  &__header {
    color: $gray100;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 26.1px */
    letter-spacing: 0.18px;
    margin-top: -32px;
  }

  &__top-button {
    width: fit-content;
    padding: 0 25px;
    height: 40px;
    min-height: 40px;

    &--no-menu {
      bottom: 20px !important;
    }

    &--add-company {
      width: 100%;

      &-admin {
        margin-top: 20px;
        margin-bottom: -20px;
      }
    }
  }
  &__fixed-button {
    @include mobile {
      position: fixed;
      bottom: 80px;
      z-index: 1;
      border-radius: 50px;
      background: var(--Black-500, #151515);
      color: #f9f9f9;
      width: calc(100% - 40px);
      left: 20px;
    }
  }
  &__tabs {
    display: flex;
    gap: 10px;
    z-index: 3;
    position: absolute;
    bottom: 16px;

    &-wrapper {
      display: flex;
      gap: 10px;
      align-items: flex-end;
      bottom: 16px;
      position: absolute;
      height: 50px;
      width: 100%;
      justify-content: space-between;
      @include mobile {
        height: 32px;
        overflow-x: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .dashboard__tabs {
        position: static;
        flex-shrink: 0;
      }

      .select__control {
        max-height: 40px !important;
        min-height: 40px !important;
      }

      .select__wrapper {
        margin-bottom: 0;
      }

      .select__label {
        position: absolute;
        color: var(--Gray-400, #e4e4e4) !important;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        bottom: calc(100% + 4px);
      }
      .posts-grid__search {
        flex-shrink: 1;
      }
    }
  }

  &__tab {
    color: #f8f8f8;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    padding: 6px 17px;
    min-width: 100px;
    border-radius: 20px;
    background: transparent;
    transition: 0.3s;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    // background-color: #efefef;
    border: 1px solid var(--Gray-100, #f8f8f8);
    text-align: center;
    &--active {
      background: var(--Gray-100, #f8f8f8);
      color: var(--Black-500, #151515);
      cursor: default;
    }

    &--locked {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &-tooltip {
      position: absolute;
      bottom: calc(100% + 10px);
      color: var(--Gray-400, #e4e4e4);
      font-family: "Source Sans Pro";
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 135%;
      max-width: 175px;
    }

    &-wrapper {
      position: relative;
    }
  }
}

.dashboard-content {
  max-width: 1221px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 20px;
  &__wrapper {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 70px;
    @include desktop {
      padding-left: 139px;
      padding-right: 80px;
      padding-bottom: 0;
    }
  }
}

.dashboard-tab-name {
  color: $graphite500;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.18px;
  margin: 20px 0;
}

.dashboard-tab-name-with-back {
  display: flex;
  align-items: center;
  &__icon {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  svg {
    margin-right: 10px;
  }
}

.dashboard-tab-name-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--only-mobile .btn {
    @include desktop {
      display: none;
    }
  }
}
