
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .company-details-modal {
  &__button {
    position: absolute;
    right: 52px;
    top: 58px;
    max-width: 152px;
    height: 40px;
    min-height: 40px;
    @include desktop {
      display: flex;
    }

    @include mobile {
      position: relative;
      top: unset;
      right: unset;
      width: 100%;
      max-width: 100%;
    }
  }

  .side-modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.company-details-table {
  &__description {
    color: var(--Black-300, #515151);
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 20px;
    white-space: pre-wrap;
  }
}

.side-modal__table-heading {
  &--description {
    margin-top: 30px;
  }
}

.side-modal__no-data {
  color: var(--Black-300, #515151);
  font-size: 14px;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
}
