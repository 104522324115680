
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.packet-modal {
  &__header {
    margin-bottom: 40px;
    @include desktop {
      margin-bottom: 67px;
    }
  }

  &--AccountFromStep {
    max-width: 400px;
    width: 100%;
  }

  &--EnterSmsCodeStep {
    max-width: 400px;
    width: 100%;
  }

  &--PaymentMethodStep {
    max-width: 400px;
    width: 100%;
  }
}
