
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .communication {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 70px 70px;
    gap: 0px;
    margin-top: 20px;

    &-header {
      display: contents;

      .column-title {
        text-align: center;
        padding: 10px 0;
        color: var(--Black-300, #515151);
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        letter-spacing: -0.14px;
      }
    }

    &-row {
      display: contents;
      &:nth-child(2) {
        .row-title {
          padding-top: 0;
        }
      }
      .row-title {
        padding: 20px 0;
        border-bottom: 1px solid #efefef;

        h4 {
          margin: 0 0 5px 0;
          color: var(--Black-500, #151515);
          font-feature-settings: "salt" on;
          font-family: "Source Sans Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 125% */
          letter-spacing: -0.16px;
        }

        p {
          margin: 0;
          color: var(--Black-300, #515151);
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
          letter-spacing: -0.14px;
        }
      }

      .checkbox-cell {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #efefef;
      }

      input[disabled] + .checkbox__dummy-input {
        opacity: 0.7;
        pointer-events: none;
        border: none;
      }

      &--disabled {
        .row-title {
          pointer-events: none;
          opacity: 0.4;
        }

        .checkbox-cell {
          pointer-events: none;
        }

        .checkbox__dummy-input {
          opacity: 0.7;
        }
      }
    }
  }

  &__banner {
    border-radius: 8px;
    padding: 20px 24px;
    background: linear-gradient(180deg, #414141 0%, #0f0f0f 100%);
    position: relative;

    display: flex;
    align-items: flex-start;
    gap: 20px;
    color: var(--White, #fff);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */
    @include mobile {
      padding: 16px;
    }
    &-svg {
      border-radius: 4px;
      background: var(--Black-300, #515151);
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }

    &-image {
      margin-bottom: -20px;
      max-width: 220px;
      margin-right: -50px;
      margin-top: -30px;
      align-self: flex-end;
      @include mobile {
        display: none;
      }
    }

    &-button {
      margin-top: 16px;
      @include mobile {
        margin-left: -52px;
        margin-top: 24px;
        width: calc(100% + 52px);
      }
    }
  }
}
