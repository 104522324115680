$black500: #151515;
$black400: #333333;
$black300: #515151;

$gray800: #9c9c9c;
$gray700: #b2b2b2;
$gray600: #c5c5c5;
$gray500: #d5d5d5;

$green: #38d58b;
$orange: #f29225;
$error: #e20d0d;
$white: #fff;

$blue800: #042237;
$blue700: #073453;
$blue600: #075388;
$blue500: #096eb5;
$blue400: #3394d8;
$blue300: #67bcf8;
$blue200: #9dd6ff;

$red800: #790f27;
$red700: #b61a3d;
$red600: #da264e;
$red500: #f53b64;
$red400: #ff5f82;
$red300: #ff89a3;
$red200: #ffbdcc;

$graphite800: #141719;
$graphite700: #202223;
$graphite600: #292c2d;
$graphite500: #333738;
$graphite400: #4a5051;
$graphite300: #6d7678;
$graphite200: #9ba7aa;

$gray400: #f2f2f2;
$gray300: #fafafa;
$gray200: #f2f2f2;
$gray100: #f2f2f2;
