
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.empty-state {
  border-radius: 4px;
  background: #fff;
  display: flex;
  max-width: 620px;
  width: 100%;
  margin: 40px auto;
  min-height: 94px;
  padding: 25px 30px;
  align-items: flex-start;
  gap: 30px;

  &--modal {
    max-width: unset;
    padding: 16px;
    gap: 16px;
  }
  &--transparent {
    .empty-state__svg {
      background: transparent;
      path {
        stroke: var(--Black-400, #9c9c9c);
      }
    }
  }

  &__svg {
    border-radius: 4px;
    background: var(--Black-400, #333);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    height: 32px;
    path {
      stroke: #fff;
    }
  }

  &__text {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.14px;
  }
  .button {
    flex-shrink: 0;
  }
}
