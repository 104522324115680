
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.homepage {
  background: var(--Gray-300, #efefef);
  width: 100%;
  padding: 43px 0;
}
