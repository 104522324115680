
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.post-page {
  &__back {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--Black-300, #515151);
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    @include Body2;
    svg {
      rect {
        fill: #f8f8f8;
      }
    }
  }

  &__content {
    border-radius: 5px;
    background: #fff;
    padding: 32px;
    display: flex;
    gap: 36px;
    @include mobile {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 40px;
    }
  }

 #html &__image {
    flex-shrink: 0;
    overflow: hidden;
    position: relative;
    object-fit: cover;
    object-position: center;
    @include desktop {
      width: 295px;
      max-width: 295px;
      height: 188px;
    }
    img {
      max-height: 183px;
      object-fit: cover;
      object-position: center;
      border-radius: 5px;
    }
  
  }

  &__content-data {
    display: block;
    line-clamp: unset;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
  }
}
