
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .companies {
  &__buttons {
    display: flex;
    gap: 20px;
  }

  &__meeting {
    border: 1.5px solid var(--Gray-300, #efefef);
    color: var(--Gray-300, #efefef);
    flex-direction: row-reverse;
    gap: 10px;
    .svg {
      margin: 0;
    }
  }

  &__top-banner {
    margin-top: 80px;
    @include mobile{
      margin-top: 24px;
    }
  }

  .dashboard-content__wrapper {
    @include desktop {
      max-height: calc(100vh - 160px);
      max-height: calc(100dvh - 160px);
      padding-bottom: 16px;
    }
  }

  .dashboard-content {
    @include desktop {
      // margin-top: 30px;
      .companies-table {
        margin-top: 0;
        table {
          margin-top: -5px;
        }
      }
    }
  }
}
