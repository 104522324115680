
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.login-wrapper {
  background: var(--Gray-300, #efefef);
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  position: relative;
  padding-inline: 20px;
  @include desktop {
    padding-inline: 0;
    padding-top: 60px;
  }

  &__content {
    border-radius: 10px;
    z-index: 2;
    background: #fff;
    margin-bottom: 60px;
    box-shadow: 29px 321px 90px 0px rgba(0, 0, 0, 0),
      19px 205px 82px 0px rgba(0, 0, 0, 0),
      11px 115px 70px 0px rgba(0, 0, 0, 0.02),
      5px 51px 52px 0px rgba(0, 0, 0, 0.03),
      1px 13px 28px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    padding: 35px 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    @include desktop {
      max-width: 400px;
      padding: 35px 50px;
    }
  }

  &__logo {
    width: 125px;
    height: auto;
    cursor: pointer;
  }

  &__background {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    background-size: cover;
    height: auto;

    @include desktop {
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__header {
    color: $black500;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 34.8px */
    letter-spacing: 0.24px;
  }

  &__subtitle {
    max-width: 300px;
    color: $black300;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    margin-top: 15px;
  }

  form {
    margin-top: 35px;
    width: 100%;
  }

  &__acceptance {
    margin-top: 20px;
  }

  &__submit {
    margin-top: 45px;
    margin-bottom: 35px;
    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__anchor {
    color: #686868;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    cursor: pointer;
    text-align: center;
    span {
      font-weight: 600;
    }
  }

  &__back {
    position: absolute;
    left: 0;
    cursor: pointer;
    top: 2px;
  }
  &__back-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    gap: 20px;
    h1 {
      padding-left: 50px;
      @include desktop {
        padding-left: 24px;
      }
    }
    justify-content: center;
  }
}
