
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.top-navigation {
  margin-bottom: 60px;
  &__container {
    background: var(--Gray-300, #efefef);
    height: 60px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 9999;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__left {
    cursor: pointer;
  }

  &__right {
    display: flex;
    gap: 50px;
    align-items: center;
  }

  &__item {
    color: var(--Graphite, #464646);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
