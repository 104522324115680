
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.post-item {
  border-radius: 3px;
  background: var(--White, #fff);
  padding: 15px;
  display: flex;
  gap: 15px;
  position: relative;
  flex-direction: column;
  @include desktop {
    padding: 15px 20px 15px 15px;
  }

  &--not-viewed {
    &:after {
      content: "";
      position: absolute;
      top: 21px;
      right: 21px;
      border-radius: 20px;
      background: #f29225;
      width: 8px;
      height: 8px;
      @include mobile {
        height: 6px;
        width: 6px;
        top: 7px;
        right: 7px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 16px;
      right: 16px;
      border-radius: 20px;
      background: rgba(242, 146, 37, 0.2);
      width: 18px;
      height: 18px;

      @include mobile {
        height: 12px;
        width: 12px;
        top: 4px;
        right: 4px;
      }
    }
  }

  &__wrapper {
    display: flex;
    gap: 15px;
    flex-direction: column;
    @include desktop {
      flex-direction: row;
    }
  }

  &__user {
    gap: 36px;
    padding: 30px 36px 26px 30px;
    .post-item__image {
      width: 100%;
      @include desktop {
        max-width: 295px;
        height: 188px;
        overflow: hidden;
        object-fit: cover;
        object-position: center;
      }

      @include mobile {
        max-height: 183px;
      }
    }
  }
  &__image {
    width: 100%;
    position: relative;
    @include mobile {
      max-height: 183px;
    }
    @include desktop {
      max-width: 171px;
      height: 125px;
      overflow: hidden;
    }
    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      border-radius: 5px;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__date {
    color: var(--Black-300, #515151);

    @include Body2;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    @include desktop {
      justify-content: flex-start;
    }
  }

  &__badges {
    display: flex;
    gap: 10px;
  }

  &__badge {
    border-radius: 50px;
    background: var(--Gray-200, #f6f6f6);
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &--delete {
      path {
        stroke: #e20d0d;
      }
    }

    &--preview {
      svg {
        max-width: 18px;
        path {
          fill: #515151;
        }
      }
    }
  }

  &__company {
    color: var(--Black-300, #515151);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    margin-top: 10px;
    margin-bottom: 13px;
  }

  &__title {
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.32px;
  }

  &__company-user {
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    border-radius: 20px;
    background: var(--Gray-300, #efefef);
    display: flex;
    align-items: center;
    height: 28px;
    padding-inline: 17px;
    width: fit-content;
  }

  &__title-user {
    @include Heading4;
    color: var(--Black-500, #151515);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__link {
    @include Body2;
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      border-radius: 50px;
      display: flex;
      align-items: center;
      gap: 4px;
      background: var(--Gray-100, #f8f8f8);
      padding: 2px 8px;
    }
  }

  &__content-data {
    @include Body2;
    color: var(--Black-300, #515151);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__date {
    color: var(--Black-300, #515151);
    @include Body2;
  }
}
