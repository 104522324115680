
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.calendar-input {
  @include paragraph;

  &__label {
    @include label;
    margin-bottom: 4px;
    text-align: left;
    display: flex;
    min-height: 21px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    position: relative;
    width: 100%;
  }
  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    margin-left: 0;
    text-align: left;
    position: absolute;
    bottom: -20px;
  }
  &__icon {
    position: absolute;
    top: 36px;
    right: 12px;
    z-index: 1;
    cursor: pointer;
    path {
      stroke: #9c9c9c;
    }
    &--delete{
      right: 48px
    }
  }
}

.calendar-input {
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.react-datepicker {
  width: 100%;
  font-family: "Source Sans Pro" !important;

  @include desktop {
    min-width: 400px;
    max-width: 400px;
  }
  border: 0 !important;
  box-shadow: 0px 3px 30px 0px rgba(32, 32, 35, 0.15) !important;
  z-index: 9;
  div &__input-container {
    input {
      @include paragraph;
      border: unset;
      background-color: #f6f6f6;
      border: 1px solid #f6f6f6;
      border-radius: 4px;
      padding: 14px 105px 14px 21px;
      width: 100%;
      min-height: 46px;
      max-height: 48px;
      &:focus {
        outline: none;
      }
    }
  }
  &__day {
    color: $graphite500;
    font-size: 15px;
    font-weight: 500;
    width: 32px !important;
    height: 32px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 4px;
    &--outside-month {
      color: #d5d5d5 !important;
    }

    &:hover {
      background-color: #efefef !important;
    }

    &--today {
      background: var(--Gray-800, #9c9c9c) !important;
      color: #fff !important;
      font-weight: 500 !important;
      &:hover {
        background: var(--Gray-800, #9c9c9c) !important;
      }
    }

    &--selected {
      background: var(--Black-500, #151515) !important;
      color: #fff !important;
      &:hover {
        background: var(--Black-500, #151515) !important;
      }
    }
  }
  &__day-names {
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__day-name {
    color: #9c9c9c !important;
    font-size: 15px;
    font-weight: 400;
    width: 32px !important;
    height: 32px !important;
  }
  &__header {
    background-color: transparent !important;
    border-bottom: 0 !important;
    padding-top: 0 !important;
  }
  &__custom-head {
    margin: 20px 0 0;
    text-align: center;
  }
  &__current-month {
    color: $graphite500;
    font-size: 18px;
    font-weight: 700;
    margin: 22px 0 15px 0 !important;
    text-transform: capitalize;
  }
  &__month-container {
    width: 100%;
    padding: 0 20px 0px 20px;
    min-height: 332px;
  }
  &__tab-loop {
    position: relative;
    z-index: 999;
  }
  &__navigation-icon--previous {
    &:before {
      color: green;
    }
  }
  &__week {
    display: flex;
    justify-content: space-between;
    div {
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__navigation {
    top: -10px !important;
  }

  &--disabled {
    color: $gray600;
    font-size: 15px;
    font-weight: 600;
  }
  &__day--keyboard-selected {
    background-color: $blue500 !important;
    color: $white !important;
  }
  &__children-container {
    width: 100% !important;
  }
  &__buttons {
    display: flex;
    gap: 15px;
    padding: 35px 40px 20px 40px;
    width: 100%;
    justify-content: space-between;
    .btn {
      min-width: 100px;
      width: 100%;
    }
  }
  &__navigation-icon {
    &:before {
      border-color: #9c9c9c !important;
    }
  }
  &__triangle {
    display: none;
  }
  &__input-time-container {
    padding: 0 36px 0 40px;
  }
}
.react-datepicker-time__caption {
  font-weight: 500;
  font-size: 15px;
}
.react-datepicker-time__input input {
  font-size: 15px;
  font-weight: 400;
  padding: 4px 10px;
  background-color: $gray400;
  border: 1px solid $gray400;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker-popper {
  width: 100%;
}
