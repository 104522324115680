
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .contact {
  position: relative;
  .layout {
    display: flex;
    justify-content: space-between;
    padding: 110px 20px;
  }
  &__left {
    z-index: 1;
    max-width: 505px;
  }
  &__right {
    z-index: 1;
    max-width: 610px;
  }
  h2 {
    @include Heading1;
    color: var(--Black-500, #151515);
    margin-bottom: 30px;
  }
  p {
    @include Body1;
    color: var(--Black-300, #515151);
  }

  &__form {
    border-radius: 10px;
    background: #fff;
    box-shadow: 29px 321px 90px 0px rgba(0, 0, 0, 0),
      19px 205px 82px 0px rgba(0, 0, 0, 0),
      11px 115px 70px 0px rgba(0, 0, 0, 0.02),
      5px 51px 52px 0px rgba(0, 0, 0, 0.03),
      1px 13px 28px 0px rgba(0, 0, 0, 0.03);
    padding: 35px 50px;

    h3 {
      color: var(--Black-500, #151515);
      font-family: "Playfair Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%;
      letter-spacing: 0.24px;
      margin-bottom: 45px;
    }

    .button {
      width: 245px;
      margin-top: 48px;
      margin-left: auto;
    }
  }

  &__form-row {
    display: flex;
    gap: 20px;
    width: 100%;
    .input__wrapper {
      width: 100%;
    }
  }

  &__bg {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 0;
    height: auto;
  }
}
