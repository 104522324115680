
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.add-payment-method {
  width: 100%;

  .login-wrapper__subtitle {
    max-width: 100%;
    margin-top: -40px;
    text-align: left;
  }
  form {
    margin-top: 40px;
    input {
      background: var(--Gray-200, #f6f6f6);
    }
  }

  &__wrap {
    display: flex;
    gap: 20px;
  }

  &__input-group {
    display: flex;
    gap: 20px;
    label {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 0px;
      .StripeElement {
        padding: 0 20px;
        background-color: #f6f6f6;
        border-radius: 3px;
      }
    }
  }

  &__info {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    margin-top: 30px;

    b {
      font-weight: 700;
    }
  }

  .checkbox__dummy-input__label {
    color: var(--Graphite, #464646);
  }

  .checkbox__dummy-input {
    background: var(--Gray-200, #f6f6f6);
    path {
      stroke: var(--Graphite, #151515);
    }
  }

  &__date {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 21.7px */
    text-align: left;
    margin-top: 30px;
    b {
      font-weight: 700;
    }
  }
}

.stripe-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
