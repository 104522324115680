
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.login-admin {
  &__dont-remember {
    color: var(--Black-300, #515151);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    width: 100%;
    text-align: left;
    margin-top: -8px;
    cursor: pointer;
  }
  .login-wrapper__submit {
    margin-bottom: 0;
  }
  &__error {
    height: 48px;
    background: #f5d6d6;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    margin-bottom: 0px;
    color: $black300;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    &--show {
      height: 48px;
      opacity: 1;
      visibility: visible;
      margin-bottom: 24px;
    }
    .svg {
      margin-right: 8px;
    }
  }
}
