
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .company-info-modal {
  &__button {
    position: absolute;
    right: 52px;
    top: 58px;
    max-width: 152px;
    height: 40px;
    min-height: 40px;
    @include desktop {
      display: flex;
    }

    @include mobile {
      position: relative;
      top: unset;
      right: unset;
      width: 100%;
      max-width: 100%;
    }
  }
  .side-modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
