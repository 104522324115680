
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.enter-code-page {
  &__anchor {
    margin-top: 35px;
  }

  .login-wrapper__content {
    position: relative;
  }
  .login-wrapper__back {
    top: 37px;
    left: 30px;
  }
}
