
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.checkbox {
  position: relative;
  input {
    display: none;
  }
  &:hover {
    cursor: pointer;
    .checkbox__dummy-input {
      border: 2px solid $gray600;
    }

    .checkbox__dummy-input--checked {
      border: 2px solid $black500;
    }
  }
  &__anchor {
    // color: $fioletowy;
    cursor: pointer;
    text-decoration: none;
  }
  &__label {
    display: flex;
    gap: 14px;
    color: #686868;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.12px;
    text-align: left;
  }

  &__dummy-input {
    width: 24px;
    height: 24px;
    display: flex;
    flex: 1;
    min-width: 24px;
    position: relative;
    border-radius: 6px;
    border: 2px solid $gray200;
    transition: 0.3s;
    max-width: 24px;
    background-color: $gray200;
    justify-content: center;
    align-items: center;
    &--checked {
      background: $black500;
      border: 2px solid $black500;
    }
  }

  &__modal {
    .checkbox__dummy-input {
      border-color: #d5d5d5;
      background-color: #f6f6f6;

      &--checked {
        background: $black500;
        border: 2px solid $black500;
      }
    }
    .checkbox__label {
      color: #333;
    }
  }

  &__svg {
    opacity: 0;
    transition: 0.3s;
    height: 16px;
    width: 16px;
    path {
      stroke: white;
    }
    &--checked {
      opacity: 1;
    }
  }

  &__error {
    color: var(#e20d0d);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 2px;
    margin-left: 0;
    position: absolute;
  }
}
