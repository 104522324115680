
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .analyzes {
  .clients-table__download {
    justify-content: flex-end;
  }

  .dashboard__tabs {
    @include mobile {
      flex-shrink: 0;
      overflow: auto;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      .dashboard__tab {
        flex-shrink: 0;
      }
    }
  }
}
