
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.edit-user {
  &__warning {
    margin-top: -15px;
    margin-bottom: 15px;

    &--hidden {
      display: none;
    }
  }
}
