
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.fullscreen-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #efefef;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 99999999;
  align-items: flex-start;
  padding-top: 100px;
  overflow-y: auto;
}

.fullscreen-modal {
  width: 100%;
  padding: 40px 20px;
  max-height: calc(100dvh - 100px);
  z-index: 3;

  @include desktop {
    padding: 0;
    max-height: 100%;
    // overflow: visible;
  }

  &--wide {
    .fullscreen-modal__content {
      max-width: 1240px;
    }
  }

  &--610 {
    .fullscreen-modal__content {
      max-width: 610px;
    }
  }

  &__step {
    color: #3c3c3b;
    position: absolute;
    font-family: "Playfair Display";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.36px;
    left: 0;
    top: -30px;
    span {
      color: #7f7f7f;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    margin: auto;
    padding-bottom: 100px;
    h2 {
      width: 100%;
    }
    @include desktop {
      max-width: 400px;
    }
  }

  &__switch {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 18.9px */
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    gap: 16px;
  }

  #html &__close {
    position: absolute;
    top: 40px;
    right: 20px;
    height: 32px;
    min-height: 32px;
    cursor: pointer;
    flex-direction: row-reverse;
    max-width: 105px;
    justify-content: center;
    gap: 10px;
    color: $black500;
    font-size: 12px;
    font-weight: 600;
    line-height: 162%; /* 22.68px */
    letter-spacing: -0.07px;
    font-feature-settings: "salt" on;
    padding-left: 15px;
    @include desktop {
      right: 150px;
    }
    .svg {
      margin-right: 0;
      width: 16px;
    }
  }

  &__header {
    color: var(--Granatowy, #464646);
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 34.8px */
    letter-spacing: 0.24px;
    text-align: left;
    margin-bottom: 40px;
  }

  &__subtitle {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */
    margin-bottom: 40px;
    margin-top: -25px;
  }
  .input {
    background-color: #f6f6f6;
  }
  .select__control {
    background-color: #f6f6f6 !important;
  }

  &__text {
    color: var(--Black-300, #515151);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    @include desktop {
      gap: 20px;
      flex-direction: row;
    }
    .input__wrapper {
      width: 100%;
    }
  }
}
