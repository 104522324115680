
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.texteditor {
  border: unset;
  resize: none;
  background-color: #f6f6f6;
  border-radius: 4px;
  min-height: 145px;
  color: #333738;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 21px;
  &:focus {
    outline: none;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__label {
    @include label;
    margin-bottom: 4px;
    text-align: left;
  }
  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 6px;
  }

  &__fonts {
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
    h2 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
    }
    h4 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    h5 {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
    h6 {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 4px;
    }
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    blockquote {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    ol {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    ul {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
  .ce-popover__search{
    display: none;
  }
}
