
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#html .button {
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 162%;
  letter-spacing: -0.07px;
  transition: 0.3s;
  color: #0e0136;
  .svg {
    margin-right: 10px;
  }

  &--mg-top-15 {
    margin-top: 15px;
  }
  &--stroke {
    border: 2px solid var(--Granatowy, #0e0136);
    background: none;
    .svg {
      path {
        stroke: #0e0136;
      }
    }
  }
  &--transparent {
    background: transparent;
  }
  &--svg-only {
    .svg {
      margin-right: 0;
    }
  }

  &--black {
    border: 1px solid var(--Black-500, #151515);
    background: var(--Black-500, #151515);
    color: #f9f9f9;
  }

  &--rounded {
    border-radius: 50px;
  }

  &--mobile-only {
    @include desktop {
      display: none;
    }
  }

  &--hide {
    display: none;
  }
  &--small {
    max-height: 32px;
    min-height: 32px;
  }
  &--max-w-200 {
    max-width: 200px;
  }

  &--payments {
    width: 200px;
    height: 32px;
    min-height: 40px;
    margin: 0;
    padding: 5px 20px 6px 20px;
  }
  &--hide-on-mobile {
    @include mobile {
      display: none;
    }
  }

  &--wide {
    width: 100%;
  }

  &--primary {
    border-radius: 50px;
    background: $black500;
    color: #f9f9f9;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      background: $black400;
    }
  }

  &--strokeBlack {
    border-radius: 50px;
    border: 1.5px solid $black500;
    color: $black500;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background: transparent;

    &:hover {
      background: $black500;
      color: #f9f9f9;
    }
  }

  &--secondary {
    border-radius: 50px;
    background: $gray300;

    color: $black500;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    &:hover {
      background: $gray100;
    }
  }

  &--strokeGray {
    border-radius: 50px;
    border: 1.5px solid $gray300;

    color: $gray300;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: transparent;

    &:hover {
      border: 1.5px solid $black500;
      background: $gray300;
      color: $black500;
    }
  }

  &--large {
    min-height: 48px;
    padding: 0 30px;
  }

  &--medium {
    min-height: 40px;
    padding: 0 25px;
  }

  &--small {
    min-height: 32px;
    padding: 0 20px;
  }

  &--loading {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &__loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
