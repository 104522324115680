
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.warning {
  border-radius: 5px;
  background: #ffe4e4;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  gap: 15px;
  color: var(--Red, #e20d0d);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  margin-bottom: 12px;
  transition: 0.3s;
  svg {
    path {
      transition: 0.3s;
    }
  }
  &--gray {
    background: var(--Gray-400, #e4e4e4);
    color: var(--Black-400, #151515);
    svg {
      path {
        stroke: #151515;
      }
    }
  }

  &--gray-with-border {
    border-radius: 5px;
    border: 1px solid var(--Gray-500, #d5d5d5);
    background: var(--Gray-100, #f8f8f8);
    color: var(--Black-400, #151515);
    svg {
      path {
        stroke: #151515;
      }
    }
    b {
      font-weight: 600;
    }
  }

  &--white {
    border-radius: 5px;
    background: #fff;
    color: var(--Black-500, #151515);
    svg {
      path {
        stroke: black;
      }
    }
  }

  &__svg {
    height: 24px;
  }

  &--shown {
    transition: 0.3s;
    overflow: hidden;

    opacity: 1;
    max-height: 100px;
  }

  &--hidden {
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin: 0;
  }
}
