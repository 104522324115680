
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.textarea {
  border: unset;
  resize: none;
  background-color: #f6f6f6;
  border-radius: 4px;
  min-height: 145px;
  color: #333738;
  font-weight: 400;
  line-height: 21px;
  padding: 14px 21px;
  &:focus {
    outline: none;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }
  &__label {
    @include label;
    margin-bottom: 4px;
    text-align: left;
    display: flex;
  }
  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 6px;
  }
}
