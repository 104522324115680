
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.post-status {
  display: flex;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: -0.12px;
  transition: 0.3s;
  width: fit-content;
  border-radius: 20px;
  height: 28px;
  align-items: center;
  padding: 0 12px;

  text-transform: uppercase;

  &--published {
    background: rgba(56, 213, 139, 0.15);
    color: #10b969;
  }

  &--draft {
    background: var(--Gray-200, #f6f6f6);
    color: var(--Black-500, #151515);
  }

  &--withdrawn {
    background: #ffe4e4;
    color: var(--Red, #e20d0d);
  }

  &--planned {
    color: var(--Black-500, #151515);
    background: var(--Gray-600, #c5c5c5);
  }
}
