
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
div.Toastify__toast {
  width: 420px;
  margin-left: auto;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.08);
  min-height: 76px;
  padding: 0;
  right: 0;
  @media (max-width: 1024px) {
    width: calc(100% - 40px);
    margin-left: 20px;
    left: 0;
  }

  p {
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    max-width: 100%;
    margin-bottom: 10px;
  }
}

div.Toastify__toast-container {
  width: 420px;
  right: 56px;
  @media (max-width: 1024px) {
    bottom: 76px;
    width: 100%;
    right: 0;
  }
}
div.Toastify__toast-icon {
  width: 48px;
  height: 100%;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: none;
  .svg {
    width: 24px;
    height: 24px;
  }
}

.Toastify__toast--success {
  .Toastify__toast-icon {
    background-color: #ddf7e0;
  }
}

.Toastify__toast--error {
  .Toastify__toast-icon {
    background-color: #ffd6d6;
  }
}

.Toastify__toast--default {
  .Toastify__toast-icon {
    background-color: #f2f2f2;
  }
}

div.Toastify__toast-body {
  padding-inline: 12px;
  color: var(--czarny-300, #333);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  padding: 0;
  margin: 0;
}
button.Toastify__close-button {
  margin-top: 16px;
  margin-right: 16px;
  opacity: 1;
  color: #a6a6a6;
}
.toast-title {
  color: var(--Black-500, #151515);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 19.6px */
  margin-bottom: 4px;
  margin-top: 10px;
}
