
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.user-status {
  display: flex;
  align-items: center;
  &__label {
    color: $black400;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    display: flex;
    border-radius: 50px;
    height: 26px;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    &--active {
      background: rgba(56, 213, 139, 0.3);
    }
    &--blocked {
      background: var(--Gray-500, #d5d5d5);
    }
    &--inactive {
      background: var(--Gray-300, #efefef);
    }
  }
}
