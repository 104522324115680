
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.register-admin {
  .login-wrapper__submit {
    margin-bottom: 0;
  }
}
