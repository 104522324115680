
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.popup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(1px);
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  z-index: 1000;
  align-items: flex-end;
  @include desktop {
    align-items: center;
  }
}

.popup-modal {
  padding: 40px 30px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
  width: 100%;

  @include desktop {
    padding: 40px 50px;
    width: 580px;
  }
  &__header {
    color: var(--Granatowy, #0e0136);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: -0.18px;
    margin-bottom: 48px;
    @include desktop {
      margin-bottom: 60px;
    }
    &--mb-20 {
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    color: #4a445d;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    letter-spacing: -0.16px;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
    @include desktop {
      gap: 20px;
      flex-direction: row;
    }
    .input__wrapper {
      width: 100%;
    }
  }
}
