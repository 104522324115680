
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.users {
  &__roles {
    display: flex;
    gap: 10px;
    margin-top: -16px;
    z-index: 2;
  }
  &__role {
    color: $gray800;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    padding: 6px 17px;
    min-width: 100px;
    border-radius: 20px;
    background: var(--Gray-300, #efefef);
    transition: 0.3s;
    cursor: pointer;
    &--active {
      background: $black300;
      color: $white;
      cursor: default;
    }
  }

  .dashboard-content__wrapper {
    @include desktop {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 160px);
      max-height: calc(100dvh - 160px);
      padding-bottom: 16px;
    }
  }

  .dashboard-content {
    @include desktop {
      // margin-top: 30px;
      .users-table {
        margin-top: 0;
        table {
          margin-top: -5px;
        }
      }
    }
  }
}
