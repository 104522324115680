
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.analyze-info-modal {
  &__form {
    margin-top: 32px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .fullscreen-modal__buttons {
      margin-top: auto;
    }
  }
  &__analyze {
    margin-top: 20px;
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  &__error {
    background: #ffe4e4;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    margin-bottom: 0px;
    color: #e20d0d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-top: -20px;
    gap: 15px;
    &--show {
      height: 60px;
      opacity: 1;
      visibility: visible;
      margin-bottom: 24px;
    }
    .svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
  }

  &__content {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__label {
    position: relative;
    .companies-table__unconfirmed-badge {
      position: absolute;
      right: 0;
      top: -4px;
    }
  }

  .side-modal__table-cell .companies-table__unconfirmed-badge {
    margin-top: 4px;
  }
}
