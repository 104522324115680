
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.companies-table {
  margin-top: 20px;
  .rc-table-placeholder {
    display: none;
  }
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  .user-status {
    padding-left: 5px;
    @include tablet {
      padding-left: unset;
    }
  }

  &--admin-other {
    @include desktop {
      .rc-table-tbody td:last-child {
        text-align: left !important;
      }
    }
  }

  &__info {
    height: 20px;
    width: 20px;
    margin-left: 20px;
    @include desktop {
      margin-left: 0;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    path {
      stroke: #9c9c9c;
    }
  }

  &--no-access {
    .companies-table__announcement {
      pointer-events: none;
      opacity: 0.4;
    }
  }
  .users-table__color {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }

  .rc-table-thead th {
    color: $black300;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    text-align: left;
    padding: 5px 20px;
    padding-bottom: 10px;
    @include desktop {
      position: sticky;
      top: 0px;
      padding-top: 34px;
      background-color: #f2f2f2;
      z-index: 1;
    }
    &:first-child {
      // border-top-left-radius: 6px;
      white-space: nowrap;
      max-width: 40px;
      padding-right: 0;
      z-index: 2;
    }
    &:last-child {
      // border-top-right-radius: 6px;
      border-color: transparent;
    }
  }
  .rc-table-tbody {
    tr {
      cursor: pointer;
      // border-bottom: 5px solid $gray300;
      box-shadow: 0px 0px 2px 0px $gray300;
      border-radius: 3px !important;
      @include tablet {
        box-shadow: 0;
      }

      &:last-child,
      &:nth-last-child(2),
      &:nth-last-child(3) {
        .context-menu--left {
          top: unset;
          bottom: 20px;
        }
      }
    }
    td {
      transition: 0.3s;
      background-color: $white;
      color: var(--Black-400, #333);
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
      letter-spacing: -0.12px;
      margin-bottom: 1px;
      padding: 15px 0;
      text-align: left;
      border: none;
      vertical-align: middle;
      @include tablet {
        padding: 12px 20px;
        // box-shadow: 0px 1px 0px 0px $gray600;
      }

      @include mobile {
        padding: 9px 0 10px;
        height: 45px;
      }

      &:first-child {
        text-align: left;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        vertical-align: middle;
        padding-right: 0;
        padding-left: 15px;
      }
      &:last-child {
        text-align: center;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        padding-right: 15px;
      }
    }
    .rc-table-cell-row-hover {
      @include tablet {
        background-color: $gray300;
      }
    }
    .rc-table-expanded-row-level-1 {
      transition: 0.3s;
      // border-bottom: 5px solid $gray300;
      // td {
      //   padding: 0;
      // }
      td {
        padding-top: 0;
      }
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -5px;
        left: 0;
        right: 0;
        height: 5px;
        background-color: #fff;
      }
    }
  }
  &__empty {
    margin-top: 150px;
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 620px;
    color: var(--Black-400, #333);
    text-align: center;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.14px;
    @include mobile {
      margin-top: 48px;
    }
  }

  &__empty-message {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.14px;
    margin-top: 10px;
  }

  &--other {
    .rc-table-cell {
      padding-top: 20px !important;
    }
  }
  &__title {
    color: var(--Black-400, #333);
    font-family: "Playfair Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.16px;
    text-align: left;
    margin-top: 24px;
    @include mobile {
      margin-top: 24px;
    }
    &--other {
      margin-top: 32px;
      margin-bottom: 10px;
    }
  }
  .pagination {
    margin-top: 15px;
  }
  &__no-data {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    &--paragraph {
      text-align: center;
      color: $graphite500;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: -0.16px;
    }
  }

  &__announcement {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    cursor: pointer;
    span {
      color: var(--White, #fff);
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
      border-radius: 100px;
      background: var(--Gray-600, #c5c5c5);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .svg {
      path {
        stroke: #333333;
      }
    }

    &--new {
      span {
        background: var(--Orange, #f29225);
      }
    }
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__other-mobile {
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $gray400;
      padding-bottom: 5px;
      h3 {
        color: var(--Black-400, #333);
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 18px */
        letter-spacing: -0.12px;
      }
    }

    &-content {
      margin-top: 12px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      p {
        font-family: "Source Sans Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: -0.12px;
        &:nth-child(odd) {
          color: var(--Gray-800, #9c9c9c);
        }
        &:nth-child(even) {
          color: #333;
        }
      }
    }
  }

  &__unconfirmed-badge {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    border-radius: 50px;
    background: var(--Gray-200, #f6f6f6);
    width: 110px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__table-row {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.user-table-row {
  &__expand {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    background-color: $white;
    padding-bottom: 15px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    // box-shadow: 0px 1px 1px 0px $gray600;
  }
  &__expand-top {
    padding-left: 0px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
  }
  // &__grid {
  //   display: grid;
  //   grid-template-columns: min-content 1fr;
  //   gap: 40px;
  //   width: 100%;
  // }
  // &__grid-item {
  //   display: flex;
  //   align-items: center;
  //   gap: 10px;
  //   &--label {
  //     color: var(--Gray-800, #9c9c9c);
  //   }
  // }
  &__expand-top-location {
    text-align: left;
    width: 50%;
  }
  &__expand-top-status {
    width: 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }
  &__expand-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
  }
  &__expand-item {
    color: $blue500;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.14px;
    margin-right: 45px;
    cursor: pointer;
    &:last-child {
      margin-right: unset;
    }
  }
}

.users-table-mobile {
  &__name {
    text-align: left;
    // padding-left: 15px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__email {
    text-align: right;
    padding-right: 20px;
    word-break: break-all;
  }
}

.side-modal .companies-table {
  margin-top: 0;
  th {
    padding-top: 0;
  }
}
