
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.drop-file-input {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__dropzone {
    width: 100%;
    height: 100px;
    border: 1px dashed #151515;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f6f6;
    cursor: pointer;
    transition: border-color 0.3s ease;

    &:hover {
      border-color: #333;
    }
  }

  &__input {
    display: none;
  }

  &__content {
    text-align: center;
    color: #666;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    p {
      color: var(--Black-300, #515151);
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    strong {
      font-weight: 600;
      color: #151515;
    }
  }
  &__file {
    font-size: 14px;
    color: #333;
    border-radius: 5px;
    border: 1px solid var(--Gray-700, #b2b2b2);
    background: var(--Gray-200, #f6f6f6);
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    svg {
      cursor: pointer;
    }
  }
}
