
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.hero {
  position: relative;
  overflow: hidden;
  .layout {
    display: flex;
    gap: 64px;
    position: relative;
  }

  &__left {
    width: 100%;
    flex: 50%;
    flex-shrink: 0;
    h1 {
      @include Heading1;
    }
    p {
      @include Body1;
      max-width: 525px;
      margin-top: 20px;
      margin-bottom: 40px;
    }
  }

  &__right {
    flex: 50%;
    width: 100%;
    position: relative;
  }

  &__mockup-mobile {
    max-height: 515px;
    width: auto;
    z-index: 1;
    position: relative;
    margin-top: 59px;
  }

  &__mockup-desktop {
    position: absolute;
    left: 61px;
    top: 0;
    height: 512px;
    width: auto;
    z-index: 0;
  }

  &__bg {
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    z-index: 0;
    height: auto;
  }
}
