
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .posts-grid {
  display: flex;
  flex-direction: column;

  &__search {
    max-width: 302px;
    width: 100%;
    margin-top: 16px;
    .select__control {
      background-color: #fff !important;
    }

    @include mobile {
      max-width: 100%;
    }

    &--admin{
      margin-left: auto;
    }
  }

  &__wrapper {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr;
    padding-bottom: 40px;
    @include desktop {
      grid-template-columns: repeat(2, 1fr);
    }

    &--user {
      @include desktop {
        grid-template-columns: 1fr;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @include desktop {
      margin-top: 24px;
    }
    @include mobile {
      margin-bottom: 20px;
      gap: 20px;
    }
    &--admin {
      justify-content: flex-end;
    }
  }

  &__categories {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include mobile {
      margin-bottom: 0;
      margin-top: 15px;
    }

    &--bottom{
      display: flex;
      gap:16px;
      margin-top: auto;
      width: 100%;
    }
  }

  &__category {
    border-radius: 50px;
    border: 1.5px solid #151515;
    transition: 0.3s;
    color: #151515;
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    cursor: pointer;

    &-icon {
      display: flex;
      width: 20px;
      height: 20px;
      overflow: hidden;
      max-width: 0;
      margin-left: 0px;
      transition: 0.3s;
      svg {
        width: 20px;
        height: 20px;
        path {
          stroke: #ffffff;
        }
      }
    }

    &--active {
      background: #151515;
      color: #f9f9f9;

      .posts-grid__category-icon {
        max-width: 20px;
        margin-left: 10px;
      }
    }
  }

  &__filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background: #151515;
    cursor: pointer;
    position: relative;

    &--active {
      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: 10px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #f14e4e;
      }
    }
  }
}
