
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
@import "./_reset";
@import "./breakpoints";
html {
  font-family: "Source Sans Pro";
}

.App {
  font-family: "Source Sans Pro";
  text-align: center;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display";
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.m-width-100 {
  max-width: 100%;
  width: 100%;
}

.Toastify__toast-container {
  z-index: 999999999999999 !important;
}

i {
  font-style: italic;
}

.desktop {
  @include mobile {
    display: none;
  }
}

.layout {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

.fw-600 {
  font-weight: 600;
}

.modal-open {
  @include mobile {
    overflow: hidden;
  }
}

.react-tooltip {
  z-index: 999999999999999 !important;
  max-width: 200px;
}

b {
  font-weight: 600;
}

html,
body {
  overscroll-behavior: none;
}
/* Add this to your global CSS file */
html.no-scroll {
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}
