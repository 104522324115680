
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.select {
  &__control {
    background-color: #f2f2f2 !important;
    border: 2px solid #f2f2f2 !important;
    min-height: 48px !important;
    box-shadow: none !important;
  }
  &__value-container {
    padding: 6px 20px !important;
    &--is-multi {
      max-height: unset !important;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 25px;
  }
  &__label {
    @include label;
    margin-bottom: 4px;
    text-align: left;
  }
  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    margin-left: 0;
    text-align: left;
    position: absolute;
    bottom: 0px;
  }
  &__indicator-separator {
    display: none !important;
  }
  &__placeholder {
    color: #4a445d !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
  }
  &__menu {
    background-color: #fff !important;
    padding: 0 !important;
    box-shadow: unset !important;
    z-index: 5 !important;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.12) !important;
  }
  &__menu-list {
    padding: 0 !important;
  }

  &__single-value {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: left;
    line-height: 150%; /* 21px */
  }
  &__option {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro" !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150%; /* 21px */
    padding: 6px 20px !important;
    // max-height: 40px;
    text-align: left;
    &:hover {
      background-color: #f8f8f8 !important;
    }
    &--is-focused {
      background-color: #f8f8f8 !important;
    }
    &--is-selected {
      background-color: #f8f8f8 !important;
      color: #4a445d !important;
    }
    &:first-child {
      border-top-right-radius: 4px !important;
      border-top-left-radius: 4px !important;
    }
    &:last-child {
      border-bottom-right-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
  &--left {
    text-align: left;
  }
}
