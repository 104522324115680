
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.add-share {
  &__summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 1rem;
    input {
      width: 145px;
      height: 32px;
    }
    span {
      position: absolute;
      right: 20px;
      color: var(--Gray-800, #9c9c9c);
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }
  }
}
