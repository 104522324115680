
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.footer {
  background: var(--Black-500, #151515);
  min-height: 233px;
  padding-top: 40px;

  &__logo {
    cursor: pointer;
    .svg {
      path {
        fill: #efefef;
      }
    }
  }
}
