
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.payments__option {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  @include mobile {
    flex-wrap: wrap;
  }
}

.payments__option-content {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 231px;

  @include mobile {
    width: 100%;
  }

  input {
    background-color: #fff;
    height: 32px;
  }
  .input__wrapper {
    width: 100%;
  }
}

#root .invoice-mailing {
  &__button {
    display: flex;
    width: 69px;
    height: 22px;
    padding: 5px 20px 6px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #f9f9f9;
    font-family: "Source Sans Pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 50px;
    background: #151515;
    position: absolute;
    min-height: 22px;
    right: 10px;
    top: 27px;

    @include mobile {
      top: 65px;
    }

    &--confirmed {
      opacity: 0;
      pointer-events: none;
    }
  }

  div &__input {
    border-radius: 3px;
    border: 1px solid var(--Gray-500, #d5d5d5);
    background: #fff;
    height: 32px;
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    padding-right: 90px;

    &--confirmed {
      padding-right: 20px;
    }
  }
  .input__wrapper {
    margin-bottom: 0;
  }

  &__warning {
    margin-top: 8px;
    b {
      font-weight: 600;
    }
  }

  .input__label {
    font-size: 12px;
    @include mobile {
      font-size: 14px;
    }
  }

  form {
    @include mobile {
      width: 100%;
    }
  }
}
