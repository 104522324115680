
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
// TabsHandler.scss
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.tab-content {
  min-height: 100vh;
  min-height: 100dvh;
  position: absolute;
  width: 100%;
  @include desktop {
    min-height: unset;
  }

  @include mobile {
    height: 100%;
    overflow: auto;
    background-color: #fff;
    top: 0 !important;
    left: 0 !important;
    position: fixed !important;
  }
}
.tabs-container {
  z-index: 99999999;
  @include desktop {
    z-index: unset;
    position: relative;
  }
}
