
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.payment-method-step {
  width: 100%;

  .login-wrapper__subtitle {
    max-width: 100%;
    margin-top: -40px;
    text-align: left;
  }
  form {
    margin-top: 40px;
    input {
      background: var(--Gray-200, #f6f6f6);
    }
  }

  .stripe-fields {
    //display: flex;
    //gap: 20px;
  }

  &__wrap {
    display: flex;
    gap: 20px;
  }
  .checkbox__dummy-input__label {
    color: var(--Graphite, #464646);
  }

  .checkbox__dummy-input {
    background: var(--Gray-200, #f6f6f6);
  }

  &__date {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 21.7px */
    text-align: left;
    margin-top: 30px;
    b {
      font-weight: 700;
    }
  }
}
