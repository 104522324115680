
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.large-switch {
  width: 100%;
  display: flex;
  border-radius: 50px;
  background: var(--Gray-200, #f6f6f6);
  padding: 3px 0;
  &__option {
    width: 100%;
    border-radius: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    padding: 0px 10px;
    cursor: pointer;
    transition: 0.3s;
    gap: 8px;
    &--active {
      background: var(--Black-400, #333);
      color: var(--White, #fff);
    }
  }
  &--bottom {
    margin-bottom: 40px;
  }

  &__icon {
    width: 16px;
    height: 16px;
    path {
      transition: 0.3s;
      stroke: transparent;
    }
    &--active {
      path {
        stroke: var(--White, #fff);
      }
    }
  }
}
