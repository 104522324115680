
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.input {
  border-radius: 4px;
  background: $gray200;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: none;
  width: 100%;
  outline: none;
  color: #4a445d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 162%; /* 22.68px */
  letter-spacing: -0.14px;

  &__label {
    width: 100%;
    display: flex;
    margin-bottom: 4px;
    @include label;

    span {
      font-weight: 300;
      color: var(--Black-300, #515151);
      margin-left: 4px;
    }
  }

  &__optional {
    color: var(--Black-300, #151515);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-left: 4px;
    font-style: italic;
  }

  &__wrapper {
    position: relative;
    margin-bottom: 25px;

    &[data-prefix] {
      input {
        padding-left: 50px;
      }
      &::before {
        content: attr(data-prefix);
        position: absolute;
        left: 20px;
        top: 40px;
        color: #96939e;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 162%; /* 22.68px */
        letter-spacing: -0.14px;
      }
    }
    &[data-suffix] {
      input {
        padding-right: 50px;
      }
      &::after {
        content: attr(data-suffix);
        position: absolute;
        right: 20px;
        top: 40px;
        color: #96939e;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 162%; /* 22.68px */
        letter-spacing: -0.14px;
      }
    }
  }

  &__eye {
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 40px;
    .svg--eye-crossed {
      margin-top: -3px;
    }
  }

  &__error {
    color: $error;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    margin-left: 0;
    position: absolute;
    left: 0;
    bottom: -20px;
  }
  &__right-label {
    color: var(--Fioletowy, #7373ed);
    font-family: "Work Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 162%; /* 22.68px */
    letter-spacing: -0.14px;
    cursor: pointer;
  }

  &__medium {
    height: 40px;
    min-height: 40px;
  }

  &__label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    label {
      margin-bottom: 0;
    }
  }

  &--currency {
    position: relative;
    span {
      position: absolute;
      right: 20px;
      top: 38px;
      color: #4a445d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 162%; /* 22.68px */
      letter-spacing: -0.14px;
    }
  }
}
