
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.clients-table {
  .rc-table-tbody td {
    padding: 0;
    vertical-align: middle;
    background: var(--Gray-200, #f6f6f6);
    padding: 14px 0 13px;
    height: unset;

    &:first-child {
      padding-left: 15px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      @include mobile {
        color: #9c9c9c;
      }
    }
    @include mobile {
      &.rc-table-cell {
        padding-top: 13px;
        padding-bottom: 13px;
      }
    }
    &:last-child {
      padding-right: 15px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      @include mobile {
        &.side-modal__table-cell {
          text-align: left;
        }
        &.rc-table-cell {
          vertical-align: top;
          padding-top: 20px;
        }
      }
    }
    &.rc-table-cell-row-hover {
      background-color: #f6f6f6;
    }

    @include mobile {
      padding: 3px 0;
    }
  }
  .rc-table-thead th {
    padding-inline: 0;
    padding-bottom: 10px;
    background-color: transparent;
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
  }
  &__download {
    cursor: pointer;
    display: flex;
  }

  &__summary {
    table {
      margin-top: 0;
      th {
        padding: 0 !important;
      }
      .rc-table-row {
        td {
          padding-top: 5px;
          padding-bottom: 5px;
          color: var(--Black-400, #333);
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 18px */
        }
      }
    }
  }
}
