
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.banner {
  border-radius: 8px;
  padding: 20px 24px;
  background: linear-gradient(180deg, #414141 0%, #0f0f0f 100%);
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--White, #fff);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  min-height: 86px;
  @include mobile {
    padding: 16px;
    flex-direction: column;
  }

  &__text {
    color: var(--White, #fff);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */
  }
  &__svg {
    border-radius: 4px;
    background: var(--Black-300, #515151);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }

  &__image {
    margin-bottom: -20px;
    margin-right: -50px;
    width: auto;
    height: calc(100% + 20px);
    max-width: 135px;
    margin-top: -60px;
    align-self: flex-end;
    @include mobile {
      display: none;
    }

    &--2 {
      img {
        max-width: 198px;
      }
    }
  }

  &__button {
    @include mobile {
      margin-top: 24px;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    @include mobile {
      gap: 20px;
    }
  }

  &__top-64 {
    margin-top: 64px;

    @include mobile {
      margin-top: 40px;
    }
  }
}
