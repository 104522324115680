
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.side-modal {
  position: fixed;
  top: 0px;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10000000;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.12);
  @include desktop {
    width: 480px;
  }
  &.wide {
    @include desktop {
      width: 676px;
    }
  }

  &__outside {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 0.3s;
    &.active {
      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(0.5px);
      z-index: 100;
    }
  }

  &.entering,
  &.entered,
  &.exiting {
    transition: transform 300ms ease-in-out;
  }

  &.exited {
    transform: translateX(100%);
  }

  &__content {
    padding: 30px 20px 80px;
    @include desktop {
      padding: 30px 40px;
    }
  }

  &--full-height {
  .side-modal__content{
    height: calc(100% - 130px);
  }
  }

  &__header-wrapper {
    position: relative;
    opacity: 0.85;
    height: 130px;
    overflow: hidden;
    background: var(
      --Dark-gradient,
      linear-gradient(180deg, #414141 0%, #0f0f0f 100%)
    );
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -1;
  }

  &__close {
    cursor: pointer;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: var(--Black-300, #515151);
    .svg {
      width: 18px;
      height: 18px;
      path {
        stroke: #d5d5d5;
        strokewidth: 2;
      }
    }
  }

  &__title {
    color: var(--White, #fff);
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 34.8px */
    letter-spacing: 0.24px;
  }

  &__subtitle {
    color: var(--Gray-500, #d5d5d5);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%; /* 20.3px */

    b {
      color: var(--Gray-500, #d5d5d5);
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 145%; /* 20.3px */
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 35px 40px;
  }

  &__header-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  &__table {
    width: 100%;

    &-heading {
      color: var(--Black-400, #333);
      font-family: "Playfair Display";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 145%; /* 29px */
      letter-spacing: 0.2px;
      margin-top: 30px;
      margin-bottom: 24px;
    }
  }
  &__table-label {
    color: #757575;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    border-bottom: 1px solid #c8c8c8;
    width: 50%;
    text-align: left;
  }

  &__table-cell {
    color: var(--Black-500, #151515);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: -0.14px;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #c8c8c8;
    text-align: left;
    width: 50%;
    
    &--space-between {
      display: flex;
      justify-content: space-between;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
  }

  &__options {
    display: flex;
    margin-top: -47px;
    gap: 10px;
  }

  &__option {
    color: var(--Black-300, #515151);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: -0.12px;
    border-radius: 20px;
    background: #efefef;
    transition: 0.3s;
    min-width: 110px;
    transition: 0.3s;
    height: 30px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &--active {
      color: var(--Black-500, #151515);
      font-weight: 600;
      background: #fff;
      cursor: default;
    }
  }
}
