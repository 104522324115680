
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.enter-sms-code-step {
  width: 100%;

  .login-wrapper__subtitle {
    max-width: 100%;
    margin-top: -40px;
    text-align: left;
  }
  .enter-code {
    margin-top: 40px;
    input {
      background: var(--Gray-200, #f6f6f6);
    }
  }
}
