@mixin desktop {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 1000px) {
    @content;
  }
}
