
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
// TimePicker.scss
.time-picker-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .time-input {
    @include paragraph;
    border: unset;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 14px 105px 14px 21px;
    width: 100%;
    min-height: 46px;
    max-height: 48px;

    &:focus {
      outline: none;
    }
  }

  .time-picker {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    width: 100%;
    border-radius: 3px;
    background: var(--White, #fff);
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    @include desktop{
        
        top: calc(100% - 15px);
    }

    .dropdown {
      display: flex;
      gap: 10px;
      .hours,
      .minutes {
        display: flex;
        flex-direction: column;
        max-height: 220px;
        overflow-y: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        gap: 3px;
        &:last-child {
          border-right: none;
        }
        ::-webkit-scrollbar {
          display: none;
        }

        &__wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .item {
          height: 21px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-self: start;
          text-align: center;
          cursor: pointer;
          transition: background 0.2s;
          color: var(--Black-400, #333);
          font-family: "Source Sans Pro";
          font-size: 14px;
          font-weight: 400;
          line-height: 150%; /* 21px */
          border-radius: 4px;

          &:hover {
            background: #efefef;
          }

          &.selected {
            background: #151515;
            color: white;
            &:hover {
              background: #151515;
            }
          }

          &.hover {
            background: #efefef;
          }
        }
      }
    }
  }
}
