
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.summary {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 24px;
  z-index: 3;
  right: 0;
  @include desktop {
    position: absolute;
    top: calc(100% - 32px);
  }

  @include mobile {
    margin-top: 24px;
  }
  &__currency {
    @include Heading4;
  }

  &__container {
    border-radius: 4px;
    background: var(--Black-300, #515151);
    display: flex;
    flex-direction: column;
    padding: 9px 26px;

    color: var(--Gray-100, #f8f8f8);
    text-align: left;
    width: 100%;
    &:first-child {
      @include desktop {
        width: 302px;
      }
    }

    &:last-child {
      @include desktop {
        width: 199px;
      }
    }
    h3 {
      font-family: "Source Sans Pro";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
      letter-spacing: -0.14px;
    }
    p {
      color: #fff;
      font-family: "Playfair Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 136%; /* 32.64px */
      letter-spacing: -0.24px;
    }
  }
}
