
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .user-info-modal {
  &__invoice-button {
    margin-top: 48px;
    height: 32px;
    border-radius: 50px;
    background: var(--Black-500, #151515);
    color: #f9f9f9;
    font-size: 12px;
    font-weight: 600;

    max-width: 181px;
    min-height: 32px;
    .svg {
      path {
        stroke: #f9f9f9;
      }
    }
  }
}
