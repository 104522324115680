
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.add-company {
  &__switch {
    margin-bottom: 20px;
  }
}
