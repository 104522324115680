
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.transactions-table {
  .rc-table-tbody td {
    padding: 0;
    vertical-align: middle;
    background: var(--Gray-200, #f6f6f6);
    padding: 14px 0 13px;
    height: unset;
    &:first-child {
      padding-left: 15px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-child {
      padding-right: 15px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      vertical-align: text-top;
    }
    &.rc-table-cell-row-hover {
      background-color: #f6f6f6;
    }
  }
  .rc-table-thead {
    @include mobile {
      display: none;
    }
  }
  .side-modal__table td {
    @include mobile {
      padding: 2px 0;
    }
  }

  .side-modal__table td.side-modal__table-cell {
    @include mobile {
      text-align: left;
    }
  }
  .side-modal__table td.side-modal__table-label {
    @include mobile {
      color: var(--Gray-800, #9c9c9c);
    }
  }
  .rc-table-thead th {
    padding: 0;
    background-color: unset;
    // padding-bottom: 10px;
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
  }
  &__download {
    cursor: pointer;
    display: flex;
  }

  &__invoice {
    @include mobile {
      margin-top: 4px;
    }
  }
  &__number {
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    &--buy {
      color: #32ad73;
    }
    &--sell {
      color: #e20d0d;
    }
  }
}
