
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;

  @include mobile {
    margin-bottom: 70px;
  }
  &--disabled {
    path {
      stroke: #cfcfcf;
    }
    cursor: default !important;
  }

  &__next {
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 0px 6px 6px 0px;
    border-left: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    .svg {
      height: 24px;
    }
  }
  &__prev {
    transform: rotate(180deg);
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 0px 6px 6px 0px;
    border-left: unset;
    display: flex;
    align-items: center;
    margin-right: 8px;
    justify-content: center;
    .svg {
      height: 24px;
    }
  }
  &__pages {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__page {
    color: #0e0136;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.38;
    width: 32px;
    height: 32px;
    border: none;
    cursor: pointer;
    transition: 0.3s;
    border-left: unset;
    border-radius: 3px;
    &--current {
      background-color: #333;
      color: #f3f3f3;
    }
  }
}
