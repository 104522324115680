
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.news {
  background: linear-gradient(251deg, #333 0%, #151515 100%);
  padding: 80px 0 128px;
  width: 100%;
  position: relative;

  .layout {
    display: flex;
    flex-direction: column;
  }
  h2 {
    @include Heading1;
    max-width: 610px;
    color: var(--White, #fff);
  }
  &__wrapper {
    display: flex;
    gap: 75px;
    row-gap: 64px;
    margin-top: 40px;
    margin-left: auto;
    max-width: 1030px;
    justify-self: flex-end;
    flex-wrap: wrap;
  }

  &__card {
    max-width: 293px;
  }

  &__card-icon {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: var(--Black-300, #515151);
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__card-title {
    color: var(--White, #fff);
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0.48px;
    margin: 15px 0;
  }

  p {
    @include Body1;
    color: var(--Gray-600, #c5c5c5);
  }

  &__bg {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    z-index: 0;
    opacity: 0.3;
    height: auto;
  }
}
