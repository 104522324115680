
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
#root .choose-packet-step {
  display: flex;
  gap: 40px;
  width: 100%;
  flex-direction: column;
  @include desktop {
    gap: 20px;
    flex-direction: row;
  }

  &__packet {
    border-radius: 8px;
    background: var(--Gray-2, #e7e7e7);
    padding: 25px 20px 75px;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.3s;
    position: relative;
    border: 1px solid #e7e7e7;
    height: 100%;

    &--selected {
      border: 1px solid var(--Black-400, #333);
      .choose-packet-step {
        &__button {
          opacity: 0.2;

          @include mobile {
            opacity: 0;
          }
        }
      }
    }

    &--pro {
      border-radius: 8px;
      background: var(--Black-500, #151515);
      border: 1px solid #151515;
      .choose-packet-step {
        &__packet-name {
          color: var(--Gray-1, var(--Gray-300, #efefef));
        }
        &__price {
          color: var(--Gray-1, var(--Gray-300, #efefef));
        }
        &__button {
          border: 1.5px solid #efefef;
          color: #efefef;
        }
        &__li {
          color: var(--Gray-1, var(--Gray-300, #efefef));
          svg {
            path {
              stroke: var(--Gray-1, var(--Gray-300, #efefef));
            }
          }
        }
      }
    }
  }
  &__button {
    transition: 0.3s;
  }

  &__description {
    color: var(--Black-400, #333);
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    text-align: left;
    margin-top: 35px;
    width: 100%;
    padding-bottom: 64px;
    b {
      font-size: 16px;
      font-weight: 600;
      line-height: 145%;
    }

    @include desktop {
      padding-bottom: 0;
      max-width: calc(25% - 15px);
      &--standard {
        margin-left: calc(25% + 10px);
      }
      &--premium {
        margin-left: calc(50% + 10px);
      }
      &--pro {
        margin-left: calc(75% + 20px);
      }
    }

    @include mobile {
      margin-top: 0;
      padding-bottom: 20px;
    }
  }

  &__submit-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    order: 10;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    width: 100%;
    background-color: #f3f3f3;
    position: fixed;
    bottom: 0px;
    max-width: 100%;
    left: 0;
    padding: 20px;
    @include desktop {
      bottom: unset;
      padding: 0;
      margin-top: 20px;
      position: relative;
      max-width: calc(25% - 15px);
    }

    @include mobile {
      flex-direction: column-reverse;
      gap: 0;
      padding-bottom: 40px;
    }
    &--show {
      opacity: 1;
      pointer-events: all;
    }
    &--right {
      margin-left: auto;
    }
    &--homepage {
      @include desktop {
        max-width: calc(25% - 15px);
      }
    }
  }

  &__award {
    position: absolute;
    right: 20px;
    top: 25px;
    border-radius: 100px;
    background: #444;
    padding: 11px;
    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__coupon {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    @include desktop {
      margin-bottom: 0px;
      position: absolute;
      right: 0;
      top: -5px;
      max-width: 295px;
    }
    b {
      font-weight: 600;
    }
    .input {
      border-radius: 3px;
      background: var(--Gray-100, #f8f8f8);
      padding-right: 105px;
    }
    .button {
      position: absolute;
      right: 15px;
      color: #f9f9f9;
      font-family: "Source Sans Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      height: 32px;
      min-height: 32px;
      width: 84px;
      top: 34px;
      @include desktop {
      }
    }
  }

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 40px;
  }

  &__selected {
    position: absolute;
    width: 100%;
    height: 35px;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--Black-400, #333);
    left: 0;
    color: var(--White, #fff);
    font-family: "Source Sans Pro";
    font-size: 12px;
    font-weight: 600;
    line-height: 150%; /* 18px */
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    @include mobile {
      top: 120px;
    }
    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__packet-name {
    color: var(--Graphite, #464646);
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.36px;
  }

  &__actual {
    color: var(--Black-500, #151515);
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    line-height: 150%; /* 21px */
    background: var(--White, #fff);
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;
  }

  &__price {
    text-align: left;
    color: var(--Black-500, #151515);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.36px;
    margin-bottom: 20px;
    span {
      font-size: 36px;
      font-weight: 500;
      line-height: 136%; /* 48.96px */
    }
  }

  hr {
    background: #bdbbbb;
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 35px;
  }
  &__ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  &__li {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    color: var(--Graphite, #464646);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%; /* 21.7px */
    text-align: left;
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
    }
    &--bold {
      font-weight: 600;
    }
  }
}
