
        @import "src/assets/styles/_variables.scss";
        @import "src/assets/styles/_typography.scss";
        @import "src/assets/styles/_breakpoints.scss";
        
.mobile-menu {
  align-items: center;
  background-color: $black500;
  bottom: 0;
  display: flex;
  height: 64px;
  justify-content: space-around;
  gap: 30px;
  width: 100vw;
  position: fixed;
  padding: 0 30px;
  left: 0;
  z-index: 9999999;
  @include desktop {
    display: none;
  }
}

.mobile-menu-item {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.active {
    .mobile-menu-item__svg-wrapper {
      background-color: $black400;
    }
  }
  &__svg-wrapper {
    transition: 0.3s;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__name {
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin-top: 2px;
    line-height: 150%; /* 15px */
  }
}
